import { IPerson } from "./interfaces";

export enum MessageTypeNames {
  TEXT_MSG = "text_message",
  INVITE_MSG = "invite_message",
  REMOVE_CHAT = "remove_chat",
  UNREMOVE_CHAT = "unremove_chat",
  REMOVE_TENANT = "remove_tenant",
  REMOVE_INTEREST = "remove_interest",
  RENT_DATES_UPDATED = "rent_dates_updated",
  CONTRACT_STATUS = "contract_status",
  REVIEW_CREATED = "review_created"
}

export enum MessageActions {
  MESSAGE_NEW = "message_new",
  MESSAGE_UPDATE = "message_update",
  MESSAGE_DELETE = "message_delete",
  MESSAGE_READ = "message_read"
}

export enum ActionsSocketNames {
  TEXT_MESSAGE_CREATED = "text_message_created",
  TEXT_MESSAGE_UPDATED = "text_message_updated",
  TEXT_MESSAGE_DELETED = "text_message_deleted",
  TEXT_MESSAGE_READ = "message_read",
  CONTRACT_STATUS = "contract_status",
  INVITE_MESSAGE_CREATED = "invite_message_created",
  INVITE_MESSAGE_DELETED = "invite_message_deleted",
  INVITE_DATES_UPDATED = "invite_dates_updated",
  RENT_DATES_UPDATED = "rent_dates_updated",
  CHAT_CREATED = "chat_created",
  CHAT_ACCEPTED = "chat_accepted",
  CHAT_REMOVED = "chat_removed",
  CHAT_UNREMOVED = "chat_unremoved",
  CHAT_DELETED = "chat_deleted", // Fires when user is removed from the system
  REVIEW_CREATED = "review_created"
}

export enum InviteStatus {
  CREATED = "created",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  REMOVED = "removed_tenant",
  INVITE_DATES_UPDATED = "invite_dates_updated"
}

export enum ChatStatus {
  ACCEPTED = "accepted",
  REQUESTED = "requested"
}
export enum ChatStatusFilter {
  ACCEPTED = "accepted",
  REQUESTED = "requested",
  ARCHIVED = "archived",
  ALL = "all"
}

export enum MessageOperation {
  CREATING = "CREATING",
  DELETING = "DELETING",
  UPDATING = "UPDATING"
}

export enum ContractStatus {
  CREATED = "created",
  FINISHED = "finished",
  REJECTED = "rejected",
  HOST_SIGNED = "host_signed"
}

export interface IMessageTextBody {
  text: string;
}
export interface IMessageInviteBody {
  invite_dates: {
    offered_check_in_date: string;
    offered_check_out_date: string;
  };
  invite_msg_status: InviteStatus;
}
export interface IMessageNewRentDatesBody {
  text: string;
  check_in_date: string;
  check_out_date: string;
}
export interface IMessageContractStatusBody {
  contract_id: string;
  contract_status_event: ContractStatus;
}
export interface IMessageReviewCreatedBody {
  text: string;
  review_created_at: string; // datetime
}

export interface ISocketMessage {
  trace_id?: string;
  action: MessageActions;
  body: { text: string };
  author_id: string;
  is_read: boolean;
  chat_id: string;
  msg_ts: string;
  created_at?: string;
  updated_at?: string;
  is_modified?: boolean;
}

export interface ISocketResponseMsg {
  author_id: string;
  body:
    | IMessageTextBody
    | IMessageInviteBody
    | IMessageNewRentDatesBody
    | IMessageContractStatusBody
    | IMessageReviewCreatedBody;
  chat_id: string;
  created_at: string;
  is_read: boolean;
  deleted: boolean;
  msg_type: MessageTypeNames;
  updated_at: string;
}

export interface ISocketResponse {
  action: ActionsSocketNames;
  body: ISocketResponseMsg;
  trace_id?: string;
  chat_id: string;
}

export interface TMessage
  extends Omit<Required<ISocketMessage>, "action" | "body" | "msg_ts"> {
  msg_type: MessageTypeNames;
  body:
    | IMessageTextBody
    | IMessageInviteBody
    | IMessageNewRentDatesBody
    | IMessageContractStatusBody
    | IMessageReviewCreatedBody;
  deleted: boolean;
  operation: MessageOperation;
}

export type TUserShort = Pick<
  IPerson,
  "first_name" | "last_name" | "thumbnail_url" | "user_id"
>;

export interface IChat {
  chat_id: string;
  chat_status: ChatStatus;
  host_most_recent_read_at?: string;
  host_most_recent_unread_at?: string;
  student_most_recent_read_at?: string;
  student_most_recent_unread_at?: string;
  request_user_unread_count: number;
  most_recent_msg?: TMessage;
  created_at: string;
  updated_at: string;
  student_id: string;
  host_id: string;
  house_id: string;
  chatted: boolean;
  removed_by_host: boolean;
  removed_by_student: boolean;
}

export interface IChatUserCounters {
  accepted: number;
  archived: number;
  requested: number;
  unread_accepted: number;
}

export interface IFulfilledChat extends IChat {
  conversation_partner_short?: TUserShort;
}

export interface IUserOnlineStatus {
  online: boolean;
  last_seen?: string;
}

export enum MobileViewMode {
  CHATS = "chats",
  MESSAGES = "messages"
}
