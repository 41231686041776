import {
  IMatch,
  IResponseShort,
  IBaseMatchData,
  TMatchData,
  IUpdateCheckoutDatePayload,
  TReview,
  TBaseReview
} from "interfaces/interfaces";
import { ServiceApi } from "../utils/utilAmplify";
import query from "../utils/query";
import { ServiceName } from "../config/amplify";

class StudentAPIClass {
  private readonly api: ServiceApi;
  private readonly reviewsApi: ServiceApi;

  constructor() {
    this.api = new ServiceApi(ServiceName.StudentMatches);
    this.reviewsApi = new ServiceApi(ServiceName.Reviews);
  }

  getMatches(userId: string): Promise<TMatchData[]> {
    return this.api.get(`student-matches/${userId}`);
  }
  acceptInvite(payload: IBaseMatchData): Promise<IResponseShort> {
    return this.api.post("accept-invite", payload);
  }
  rejectInvite(payload: IBaseMatchData): Promise<IResponseShort> {
    return this.api.post("reject-invite", payload);
  }
  getMatch(
    studentId: string,
    hostId: string,
    houseId: string,
    options?: { showNotificationOnError?: boolean }
  ): Promise<IMatch> {
    return this.api
      .get(
        "details?" +
          query.stringify({
            student_id: studentId,
            host_id: hostId,
            house_id: houseId
          }),
        options
      )
      .then((res) => res.data);
  }
  showInterest(
    payload: IBaseMatchData,
    message: string
  ): Promise<IResponseShort> {
    return this.api.post("show-interest", {
      student_id: payload.student_id,
      host_id: payload.host_id,
      house_id: payload.house_id,
      message
    });
  }
  updateCheckoutDate(
    payload: IUpdateCheckoutDatePayload
  ): Promise<IResponseShort> {
    return this.api.post("change-checkout-date", payload);
  }
  // Reviews:
  createReview(
    payload: Pick<TReview, "rating" | "review_text" | "house_id">,
    host_id: string
  ): Promise<TReview> {
    return this.reviewsApi.post(host_id, payload);
  }
  updateReview(
    payload: Partial<TBaseReview> & { created_at: string },
    host_id: string
  ): Promise<IResponseShort> {
    return this.reviewsApi.patch(host_id, payload);
  }
}

export const StudentAPI = new StudentAPIClass();
