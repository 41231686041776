import { FC, ReactNode, Suspense } from "react";
import styled from "styled-components/macro";
import { MenuLeftSide } from "../MenuLeftSide";
import { LoaderCustom } from "../Loader";

const Root = styled.div<{ $visible: boolean }>`
  background: white;
  display: ${(p) => (p.$visible ? "flex" : "none")};
`;
const Content = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const LayoutWithLeftMenu: FC<{
  children: ReactNode;
  visible?: boolean;
}> = ({ children, visible = true }) => {
  return (
    <Root $visible={visible}>
      <div>
        <MenuLeftSide />
      </div>
      <Content>
        <Suspense
          fallback={<LoaderCustom size={5} text="" whiteBackground={true} />}
        >
          {children}
        </Suspense>
      </Content>
    </Root>
  );
};
