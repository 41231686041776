import { Amplify } from "aws-amplify";
import { getHeaderAuth } from "../utils/utilAmplify";
import {
  API_CHATS,
  API_HOUSES,
  API_MATCHES,
  API_MATCHES_CONTRACTS,
  API_USERS,
  API_USERS_REVIEWS
} from "../utils/constants";
import { isLocalhost } from "../utils/helpers";

const endPointHospi = process.env.REACT_APP_AWS_ENDPOINT;

export enum ServiceName {
  UsersProfile = "UsersProfile",
  Reviews = "Reviews",
  HostHouse = "HostHouse",
  StudentMatches = "StudentMatches",
  HostMatches = "HostMatches",
  Contracts = "Contracts",
  UserChats = "UserChats",
  Public = "Public"
}

export const defaultAmplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_POOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: isLocalhost
        ? `http://localhost:3000/`
        : process.env.REACT_APP_OAUTH_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_URL,
      responseType: "code",
      socialProviders: ["Google"]
    }
  },
  API: {
    endpoints: [
      {
        name: ServiceName.UsersProfile,
        endpoint: endPointHospi + API_USERS,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.Reviews,
        endpoint: endPointHospi + API_USERS_REVIEWS,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.HostHouse,
        endpoint: endPointHospi + API_HOUSES,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.StudentMatches,
        endpoint: endPointHospi + API_MATCHES,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.HostMatches,
        endpoint: endPointHospi + API_MATCHES,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.Contracts,
        endpoint: endPointHospi + API_MATCHES_CONTRACTS,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.UserChats,
        endpoint: endPointHospi + API_CHATS,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.Public,
        endpoint: endPointHospi,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      }
    ]
  }
};

export const initAmplify = (): void => {
  Amplify.configure(defaultAmplifyConfig);
};
