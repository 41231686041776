import { FC, useMemo } from "react";
import { ChevronLeft } from "@styled-icons/bootstrap/ChevronLeft";
import { ChevronRight } from "@styled-icons/bootstrap/ChevronRight";

import { RouteIcons } from "../../routes";
import { useCurrentUser, useIsHost } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { VerificationBanner } from "../VerificationBanner";
import { Tooltip } from "antd";
import { Menu, TMenuItem } from "./Menu";
import { useMenuCollapsedState } from "./hooks";
import {
  Root,
  ToggleButton,
  MenuContainer,
  VerificationIcon,
  MenuItemIcon,
  MenuItem
} from "./styles";
import { useChat } from "../../context/ContextChat/ContextChat";
import { isChatPath, useGetProfileMenuRoutes } from "../Menu/utils";
import { useMediaQuery } from "../../utils/hooks/useWindowSize";

export const MenuLeftSide: FC = () => {
  const { isCollapsed, setIsCollapsed } = useMenuCollapsedState();
  const isHost = useIsHost();
  const { t, i18n } = useTranslation("header");
  const navigate = useNavigate();
  const location = useLocation();
  const userProfile = useCurrentUser();
  const showVerificationBanner =
    userProfile?.verification_status !== "verified";

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const {
    state: { chatCounters }
  } = useChat();

  const unreadCount = isHost
    ? (chatCounters?.requested ?? 0) + (chatCounters?.unread_accepted ?? 0)
    : chatCounters?.unread_accepted ?? 0;

  const menuRoutes = useGetProfileMenuRoutes();

  const menuItems: TMenuItem[] = useMemo(() => {
    return menuRoutes
      .filter((i) => i.keyLocalize !== "tips")
      .map((item) => ({
        label: t(`subMenu.${item.keyLocalize}`),
        icon: item.icon ?? <RouteIcons.Profile />,
        value: generatePath(item.path, {
          lng: i18n.language,
          id: isChatPath(item.path) ? "all" : ""
        }),
        counter:
          isChatPath(item.path) && unreadCount > 0 ? unreadCount : undefined
      }));
  }, [unreadCount, t, i18n.language, menuRoutes]);

  const isCollapsedForSmallScreen = useMediaQuery("(max-width: 1200px)");
  const finalCollapsed = isCollapsed || isCollapsedForSmallScreen;

  const verificationStatuses = {
    unverified: {
      showBanner: true,
      message: t("leftSide.unverified")
    },
    verifying: {
      showBanner: true,
      message: t("leftSide.verifying")
    },
    verified: {
      showBanner: false,
      message: t("leftSide.verified")
    },
    failed: {
      showBanner: false,
      message: t("leftSide.failed")
    }
  };
  const currentStatus =
    verificationStatuses[userProfile?.verification_status || "unverified"];

  return (
    <Root
      isCollapsed={finalCollapsed}
      isCollapsedForSmallScreen={isCollapsedForSmallScreen}
    >
      <MenuContainer>
        <Menu
          items={menuItems}
          value={location.pathname}
          isCollapsed={finalCollapsed}
          onItemClick={(item) => {
            navigate(item.value);
          }}
        />
        {isHost && !finalCollapsed && <VerificationBanner />}
        {isHost && showVerificationBanner && finalCollapsed ? (
          <Tooltip title={currentStatus.message} placement="right">
            <MenuItem
              style={{ marginTop: "20px" }}
              $isActive={false}
              $isCollapsed={true}
            >
              <MenuItemIcon>
                <VerificationIcon />
              </MenuItemIcon>
            </MenuItem>
          </Tooltip>
        ) : null}
      </MenuContainer>
      <ToggleButton
        onClick={toggleCollapsed}
        disabled={isCollapsedForSmallScreen}
      >
        {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
      </ToggleButton>
    </Root>
  );
};
