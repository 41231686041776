import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  createContext
} from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ActionsSocketNames,
  ChatStatusFilter,
  ContractStatus,
  IChat,
  IFulfilledChat,
  IMessageContractStatusBody,
  IMessageInviteBody,
  IMessageReviewCreatedBody,
  IMessageNewRentDatesBody,
  InviteStatus,
  ISocketMessage,
  ISocketResponse,
  MessageActions,
  MessageOperation,
  MessageTypeNames,
  MobileViewMode,
  TMessage,
  TUserShort
} from "../../interfaces/interfacesChat";
import { ChatsAPI } from "../../API/chatsApi";
import { useContextHospi } from "../ContextHospi";
import { createFulfilledChats, getChatPartnerId } from "../../utils/chat";
import { UserAPI } from "../../API/userApi";
import { API } from "aws-amplify";

import {
  CHAT_INITIAL_STATE,
  ChatActions,
  chatReducer,
  IChatState,
  TChatAction
} from "./reducer";
import { Socket, SocketEvents } from "../../utils/Socket";
import { useSocket } from "../SocketProvider";
import { useQueryClient } from "@tanstack/react-query";
import {
  getMatchQueryKey,
  getStudentMatchesQueryKey
} from "../../API/queries/studentApiQueries";
import {
  FETCHED_CHATS_BATCH_AMOUNT,
  HOST_CHATS_ROUTE_ID,
  MatchStatusEnum,
  STUDENT_CHATS_ROUTE_ID
} from "../../utils/constants";
import { IMatch, TMatchData, TReview } from "../../interfaces/interfaces";
import {
  getHouseQueryKey,
  getHousesQueryKey
} from "../../API/queries/houseApiQueries";
import { getHostMatchesQueryKey } from "../../API/queries/hostApiQueries";
import { StudentAPI } from "../../API/studentApi";
import { HostAPI } from "../../API/hostApi";
import { getReviewsQueryKey } from "../../API/queries/userApiQueries";

type Reducer<S, A> = (prevState: S, action: A) => S;

interface IContextChat {
  state: IChatState;
  setActiveChatId: (id: string | null) => void;
  loadMoreMessages: () => void;
  readMessage: (message: {
    author_id: string;
    chat_id: string;
    created_at: string;
  }) => void;
  editMessage: (message: {
    author_id: string;
    chat_id: string;
    created_at: string;
    body: { text: string };
  }) => void;
  deleteMessage: (message: {
    author_id: string;
    chat_id: string;
    created_at: string;
    body: { text: string };
  }) => void;
  sendMessage: (message: {
    author_id: string;
    chat_id: string;
    msg_ts: string;
    body: { text: string };
  }) => void;
  setChatsFilteredByTab: (chats: IFulfilledChat[]) => void;
  changeMobileViewMode: (mode: MobileViewMode | null) => void;
}

export type TChatReducer = Reducer<IChatState, TChatAction>;

const ContextChat = createContext<IContextChat>({
  state: CHAT_INITIAL_STATE,
  setActiveChatId: () => undefined,
  loadMoreMessages: () => undefined,
  readMessage: () => undefined,
  editMessage: () => undefined,
  deleteMessage: () => undefined,
  sendMessage: () => undefined,
  setChatsFilteredByTab: () => undefined,
  changeMobileViewMode: () => undefined
});

const reduxLogger = <S, A, R extends Reducer<S, A>>(reducer: R) => {
  return (state: S, action: A): S => {
    // console.log("reduxLogger:old state", action, state);
    const newState = reducer(state, action);
    // console.log("reduxLogger:new state", action, newState);
    return newState;
  };
};

export const useChat = () => {
  return useContext(ContextChat);
};

const useCacheHelpers = () => {
  const queryClient = useQueryClient();
  const { currentUserRole, currentUserId } = useContextHospi();

  const invalidateMatch = useCallback(
    (chat: IFulfilledChat | undefined) => {
      if (!chat) {
        return;
      }
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: chat.student_id,
          hostId: chat.host_id,
          houseId: chat.house_id
        })
      });
      if (currentUserRole === "student") {
        queryClient.invalidateQueries({
          queryKey: getStudentMatchesQueryKey({ userId: currentUserId })
        });
      }
    },
    [queryClient, currentUserRole, currentUserId]
  );

  const changeMatchStatus = useCallback(
    (chat: IFulfilledChat | undefined, matchStatus: MatchStatusEnum) => {
      if (!chat) {
        return;
      }
      queryClient.setQueryData<IMatch>(
        getMatchQueryKey({
          studentId: chat.student_id,
          hostId: chat.host_id,
          houseId: chat.house_id
        }),
        (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              match_status: matchStatus
            };
          }
        }
      );
    },
    [queryClient, currentUserRole, currentUserId]
  );

  const invalidateHouse = useCallback(
    (houseId: string) => {
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(houseId)
      });
    },
    [queryClient]
  );
  const invalidateHouses = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: getHousesQueryKey(currentUserId)
    });
  }, [queryClient, currentUserId]);

  return useMemo(() => {
    return {
      invalidateMatch,
      changeMatchStatus,
      invalidateHouse,
      invalidateHouses
    };
  }, [invalidateMatch, changeMatchStatus, invalidateHouse, invalidateHouses]);
};

export const createReadMessageObject = (
  chatId: string,
  authorId: string,
  createdAt: string,
  propagate: boolean = false
) => {
  return {
    action: MessageActions.MESSAGE_READ,
    author_id: authorId,
    chat_id: chatId,
    created_at: createdAt,
    propagate,
    body: { text: "read" }
  };
};

const fulfillChats = async (
  chats: IChat[],
  userRole: string
): Promise<IFulfilledChat[] | null> => {
  const userIds = Array.from(
    new Set(chats.map((chat) => getChatPartnerId(chat, userRole)))
  );

  if (userIds.length === 0) {
    return null;
  }

  const {
    users_short_description: usersDescription
  }: {
    users_short_description: TUserShort[];
  } = await UserAPI.getShortDescription(userIds);

  return createFulfilledChats(chats, usersDescription, userRole);
};

export const ChatProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { currentUserId, currentUserRole } = useContextHospi();
  const { instance: socket } = useSocket();
  const cacheHelpers = useCacheHelpers();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer<TChatReducer, IChatState>(
    reduxLogger(chatReducer),
    CHAT_INITIAL_STATE,
    (value) => value
  );
  const queryClient = useQueryClient();
  const stateRef = useRef(state);
  stateRef.current = state;

  const loadChats = useCallback(async () => {
    if (!currentUserId || !currentUserRole) return;

    dispatch({ type: ChatActions.SET_CHATS_IS_LOADING, payload: true });

    try {
      const allFulfilledChats: IFulfilledChat[] = [];
      let nextBatchToken = null;

      do {
        const searchResult: {
          chats: IChat[];
          next_batch_token: string | null;
        } = await ChatsAPI.getUserChats(
          currentUserId,
          ChatStatusFilter.ALL,
          FETCHED_CHATS_BATCH_AMOUNT,
          nextBatchToken
        );

        const fulfilledChats = await fulfillChats(
          searchResult.chats,
          currentUserRole
        );

        if (fulfilledChats !== null) {
          allFulfilledChats.push(...fulfilledChats);
        }

        nextBatchToken = searchResult.next_batch_token;
      } while (nextBatchToken);

      dispatch({ type: ChatActions.SET_CHATS, payload: allFulfilledChats });
    } finally {
      dispatch({ type: ChatActions.SET_CHATS_IS_LOADING, payload: false });
    }
  }, [currentUserId, currentUserRole]);

  const loadChatCounters = useCallback(() => {
    ChatsAPI.getUserCounters().then((value) => {
      dispatch({
        type: ChatActions.SET_CHAT_COUNTERS,
        payload: value
      });
    });
  }, []);

  useEffect(() => {
    if (currentUserId && currentUserRole) {
      loadChatCounters();
      dispatch({
        type: ChatActions.SET_USER_ROLE,
        payload: currentUserRole
      });
      loadChats();
    } else {
      dispatch({ type: ChatActions.RESET, payload: null });
    }
  }, [currentUserId, currentUserRole]);

  useEffect(() => {
    if (state.activeChatId && currentUserId && socket) {
      dispatch({
        type: ChatActions.SET_ACTIVE_CHAT_MESSAGES_IS_LOADING,
        payload: true
      });
      dispatch({
        type: ChatActions.SET_CHAT_UNREAD_COUNT,
        payload: {
          chatId: state.activeChatId,
          count: 0
        }
      });
      const messagesPromise = ChatsAPI.getChatMessages(state.activeChatId);
      messagesPromise
        .then((result) => {
          dispatch({
            type: ChatActions.SET_ACTIVE_CHAT_MESSAGES,
            payload: result.messages
          });
          for (const message of result.messages) {
            if (message.author_id !== currentUserId) {
              // always send read event, even for read messages,
              // to read all previous messages which could be not read with past version of api
              socket.send(
                createReadMessageObject(
                  message.chat_id,
                  currentUserId,
                  message.created_at,
                  true
                )
              );
              break;
            }
          }
        })
        .finally(() => {
          dispatch({
            type: ChatActions.SET_ACTIVE_CHAT_MESSAGES_IS_LOADING,
            payload: false
          });
        });

      return () => {
        API.cancel(messagesPromise);
      };
    } else {
      dispatch({ type: ChatActions.SET_ACTIVE_CHAT_MESSAGES, payload: [] });
    }
  }, [state.activeChatId, currentUserId, socket]);

  const loadMoreMessages = useCallback(() => {
    if (state.isLoadingActiveChatMessages || state.isLoadingMoreChatMessages) {
      return;
    }

    const activeChatId = state.activeChatId;
    if (activeChatId && state.activeChatMessages.messages.length > 0) {
      const lastMessage = state.activeChatMessages.messages[0];
      const beforeDt = lastMessage ? lastMessage.created_at : null;
      if (!beforeDt) {
        return;
      }
      dispatch({
        type: ChatActions.SET_MORE_CHAT_MESSAGES_IS_LOADING,
        payload: true
      });
      ChatsAPI.getChatMessages(activeChatId, beforeDt)
        .then((result) => {
          dispatch({
            type: ChatActions.ADD_ACTIVE_CHAT_MESSAGES,
            payload: {
              messages: result.messages,
              chatId: activeChatId
            }
          });
        })
        .finally(() => {
          dispatch({
            type: ChatActions.SET_MORE_CHAT_MESSAGES_IS_LOADING,
            payload: false
          });
        });
    }
  }, [
    state.activeChatId,
    state.activeChatMessages,
    state.isLoadingActiveChatMessages,
    state.isLoadingMoreChatMessages
  ]);

  const goToNextChatInLine = useCallback(
    (
      chatsFilteredByTab: IFulfilledChat[],
      userRole: "student" | "host",
      updatingChat: IFulfilledChat
    ) => {
      const currentChatIndex = chatsFilteredByTab.findIndex(
        (c) => c.chat_id === updatingChat.chat_id
      );
      if (currentChatIndex !== -1) {
        const nextChatInLine = chatsFilteredByTab[currentChatIndex + 1];
        navigate(
          generatePath(
            userRole === "host" ? HOST_CHATS_ROUTE_ID : STUDENT_CHATS_ROUTE_ID,
            {
              lng: i18n.language,
              id: nextChatInLine?.chat_id || "all"
            }
          )
        );
        if (state.mobileViewMode !== null) {
          dispatch({
            type: ChatActions.CHANGE_MOBILE_VIEW_MODE,
            payload: MobileViewMode.MESSAGES
          });
        }
      }
    },
    []
  );

  useEffect(() => {
    if (socket && currentUserId) {
      const messageHandler = (message: ISocketResponse) => {
        const state = stateRef.current;

        const messageChat = state.chats.find(
          (c) => c.chat_id === message?.chat_id
        );

        if (message.action === ActionsSocketNames.TEXT_MESSAGE_CREATED) {
          dispatch({
            type: ChatActions.PROCESS_NEW_MESSAGE,
            payload: {
              trace_id: message.trace_id,
              ...message.body
            }
          });
          if (
            message.body.chat_id === state.activeChatId &&
            message.body.author_id !== currentUserId
          ) {
            socket.send(
              createReadMessageObject(
                message.body.chat_id,
                currentUserId,
                message.body.created_at
              )
            );
          }
          if (message.body.msg_type === MessageTypeNames.REMOVE_TENANT) {
            cacheHelpers.invalidateMatch(messageChat);
            cacheHelpers.invalidateHouses();
            if (messageChat?.house_id) {
              cacheHelpers.invalidateHouse(messageChat.house_id);
            }
          }
        } else if (message.action === ActionsSocketNames.TEXT_MESSAGE_READ) {
          dispatch({
            type: ChatActions.PROCESS_READ_MESSAGE,
            payload: message.body
          });
        } else if (message.action === ActionsSocketNames.TEXT_MESSAGE_UPDATED) {
          dispatch({
            type: ChatActions.UPDATE_MESSAGE,
            payload: message.body
          });
        } else if (message.action === ActionsSocketNames.TEXT_MESSAGE_DELETED) {
          dispatch({
            type: ChatActions.UPDATE_MESSAGE,
            payload: message.body
          });
        } else if (
          message.action === ActionsSocketNames.INVITE_MESSAGE_CREATED
        ) {
          dispatch({
            type: ChatActions.PROCESS_NEW_MESSAGE,
            payload: message.body
          });

          if (message.body.msg_type === MessageTypeNames.INVITE_MSG) {
            const inviteMessageStatus = (
              message.body.body as IMessageInviteBody
            ).invite_msg_status;

            if (
              !!messageChat &&
              (inviteMessageStatus === InviteStatus.CREATED ||
                inviteMessageStatus === InviteStatus.REMOVED ||
                inviteMessageStatus === InviteStatus.REJECTED ||
                inviteMessageStatus === InviteStatus.INVITE_DATES_UPDATED)
            ) {
              queryClient.invalidateQueries({
                queryKey: getMatchQueryKey({
                  studentId: messageChat.student_id,
                  hostId: messageChat.host_id,
                  houseId: messageChat.house_id
                })
              });
            } else cacheHelpers.invalidateMatch(messageChat);
            if (
              inviteMessageStatus === InviteStatus.ACCEPTED ||
              inviteMessageStatus === InviteStatus.REJECTED ||
              inviteMessageStatus === InviteStatus.REMOVED
            ) {
              cacheHelpers.invalidateHouses();
              if (messageChat?.house_id) {
                cacheHelpers.invalidateHouse(messageChat.house_id);
              }
              if (
                inviteMessageStatus === InviteStatus.ACCEPTED &&
                currentUserRole === "host"
              ) {
                queryClient.invalidateQueries({
                  queryKey: getHostMatchesQueryKey({
                    userId: currentUserId
                  })
                });
              }
            }
          } else cacheHelpers.invalidateMatch(messageChat);

          if (message.body.chat_id === state.activeChatId) {
            socket.send(
              createReadMessageObject(
                message.body.chat_id,
                currentUserId,
                message.body.created_at
              )
            );
          }
        } else if (
          message.action === ActionsSocketNames.INVITE_MESSAGE_DELETED
        ) {
          dispatch({
            type: ChatActions.REMOVE_MESSAGE,
            payload: message.body
          });
          if (messageChat)
            queryClient.invalidateQueries({
              queryKey: getMatchQueryKey({
                studentId: messageChat.student_id,
                hostId: messageChat.host_id,
                houseId: messageChat.house_id
              })
            });
        } else if (message.action === ActionsSocketNames.INVITE_DATES_UPDATED) {
          dispatch({
            type: ChatActions.UPDATE_MESSAGE,
            payload: message.body
          });
          if (messageChat)
            queryClient.invalidateQueries({
              queryKey: getMatchQueryKey({
                studentId: messageChat.student_id,
                hostId: messageChat.host_id,
                houseId: messageChat.house_id
              })
            });
        } else if (message.action === ActionsSocketNames.RENT_DATES_UPDATED) {
          dispatch({
            type: ChatActions.UPDATE_MESSAGE,
            payload: message.body
          });

          if (messageChat) {
            if (message.body.author_id !== currentUserId) {
              const { check_in_date, check_out_date } = message.body
                .body as IMessageNewRentDatesBody;

              if (currentUserRole === "student") {
                StudentAPI.getMatch(
                  messageChat.student_id,
                  messageChat.host_id,
                  messageChat.house_id,
                  {
                    showNotificationOnError: false
                  }
                )
                  .then((result) => {
                    queryClient.setQueryData<IMatch>(
                      getMatchQueryKey({
                        studentId: messageChat.student_id,
                        hostId: messageChat.host_id,
                        houseId: messageChat.house_id
                      }),
                      (oldData) => {
                        if (oldData) {
                          return {
                            ...oldData,
                            check_in_date,
                            check_out_date,
                            checkout_date_actor:
                              result.checkout_date_actor ?? null
                          };
                        }
                      }
                    );
                    queryClient.setQueryData<TMatchData[]>(
                      getStudentMatchesQueryKey({ userId: currentUserId }),
                      (oldData) => {
                        if (oldData) {
                          return oldData.map((m) => {
                            if (
                              m.student_id === messageChat.student_id &&
                              m.house_id === messageChat.house_id &&
                              m.host_id === messageChat.host_id
                            ) {
                              return {
                                ...m,
                                check_in_date,
                                check_out_date,
                                checkout_date_actor:
                                  result.checkout_date_actor ?? null
                              };
                            } else return m;
                          });
                        }
                      }
                    );
                  })
                  .catch((e) => {
                    if (e?.response?.status === 404) {
                      return {
                        student_id: messageChat.student_id,
                        host_id: messageChat.host_id,
                        house_id: messageChat.house_id,
                        match_status: undefined,
                        contract_id: null,
                        contract_status: null,
                        payment_status: null,
                        payment_link: null
                      } as IMatch;
                    }
                    return Promise.reject(e);
                  });
              }

              if (currentUserRole === "host") {
                HostAPI.getMatch(
                  messageChat.student_id,
                  messageChat.host_id,
                  messageChat.house_id,
                  {
                    showNotificationOnError: false
                  }
                ).then((result) => {
                  queryClient.setQueryData<IMatch>(
                    getMatchQueryKey({
                      studentId: messageChat.student_id,
                      hostId: messageChat.host_id,
                      houseId: messageChat.house_id
                    }),
                    (oldData) => {
                      if (oldData) {
                        return {
                          ...oldData,
                          check_in_date,
                          check_out_date,
                          checkout_date_actor:
                            result.checkout_date_actor ?? null
                        };
                      }
                    }
                  );
                  queryClient.setQueryData<TMatchData[]>(
                    getHostMatchesQueryKey({ userId: currentUserId }),
                    (oldData) => {
                      if (oldData) {
                        return oldData.map((m) => {
                          if (
                            m.student_id === messageChat.student_id &&
                            m.house_id === messageChat.house_id &&
                            m.host_id === messageChat.host_id
                          ) {
                            return {
                              ...m,
                              check_in_date,
                              check_out_date,
                              checkout_date_actor:
                                result.checkout_date_actor ?? null
                            };
                          } else return m;
                        });
                      }
                    }
                  );
                });
              }
            }
          }
        } else if (message.action === ActionsSocketNames.CONTRACT_STATUS) {
          dispatch({
            type: ChatActions.PROCESS_NEW_MESSAGE,
            payload: message.body
          });
          const messageBody = message.body.body as IMessageContractStatusBody;

          if (
            messageBody.contract_status_event === ContractStatus.HOST_SIGNED &&
            currentUserRole === "student" &&
            !!messageChat
          ) {
            queryClient.invalidateQueries({
              queryKey: getMatchQueryKey({
                studentId: messageChat.student_id,
                hostId: messageChat.host_id,
                houseId: messageChat.house_id
              })
            });
            queryClient.setQueryData<TMatchData[]>(
              getStudentMatchesQueryKey({ userId: currentUserId }),
              (oldData) => {
                if (oldData) {
                  return oldData.map((m) => {
                    if (
                      m.student_id === messageChat.student_id &&
                      m.house_id === messageChat.house_id &&
                      m.host_id === messageChat.host_id
                    ) {
                      return {
                        ...m,
                        contract_status: ContractStatus.HOST_SIGNED
                      };
                    } else return m;
                  });
                }
              }
            );
          } else cacheHelpers.invalidateMatch(messageChat);

          if (
            currentUserRole === "host" &&
            (messageBody.contract_status_event === ContractStatus.FINISHED ||
              messageBody.contract_status_event === ContractStatus.REJECTED)
          ) {
            queryClient.invalidateQueries({
              queryKey: getHostMatchesQueryKey({
                userId: currentUserId
              })
            });
          }

          if (
            message.body.chat_id === state.activeChatId &&
            message.body.author_id !== currentUserId
          ) {
            socket.send(
              createReadMessageObject(
                message.body.chat_id,
                currentUserId,
                message.body.created_at
              )
            );
          }
        } else if (message.action === ActionsSocketNames.REVIEW_CREATED) {
          if (message.body.author_id !== currentUserId) {
            dispatch({
              type: ChatActions.PROCESS_NEW_MESSAGE,
              payload: message.body
            });

            const messageBody = message.body.body as IMessageReviewCreatedBody;
            UserAPI.getReview(
              currentUserId,
              messageBody.review_created_at
            ).then((response) => {
              queryClient.setQueryData<TReview[]>(
                getReviewsQueryKey({ hostId: currentUserId }),
                (oldData) => {
                  if (oldData) {
                    return [...oldData, response];
                  }
                }
              );
            });
          }

          if (message.body.chat_id === state.activeChatId)
            socket.send(
              createReadMessageObject(
                message.body.chat_id,
                currentUserId,
                message.body.created_at
              )
            );
        } else if (message.action === ActionsSocketNames.CHAT_CREATED) {
          // fetch chat and add to the list
          if (!state.chats.find((c) => c.chat_id === message.chat_id)) {
            ChatsAPI.getChat(message.chat_id).then((chat) => {
              fulfillChats([chat], state.userRole!).then((fulfilledChats) => {
                if (fulfilledChats?.[0]) {
                  dispatch({
                    type: ChatActions.ADD_CHAT,
                    payload: fulfilledChats?.[0]
                  });
                }
              });
            });
          }
        } else if (message.action === ActionsSocketNames.CHAT_DELETED) {
          dispatch({
            type: ChatActions.REMOVE_CHAT,
            payload: {
              chatId: message.chat_id
            }
          });
          cacheHelpers.invalidateMatch(messageChat);
        } else if (message.action === ActionsSocketNames.CHAT_REMOVED) {
          const updatingChat = state.chats.find(
            (c) => c.chat_id === message.chat_id
          );

          if (updatingChat) {
            const isHostAction =
              updatingChat.host_id === message.body.author_id;
            const isStudentAction =
              updatingChat.student_id === message.body.author_id;

            if (
              (state.userRole === "student" && isStudentAction) ||
              (state.userRole === "host" && isHostAction)
            ) {
              dispatch({
                type: ChatActions.REMOVE_CHAT,
                payload: {
                  chatId: message.chat_id
                }
              });
              const { chatsFilteredByTab, userRole } = state;
              goToNextChatInLine(chatsFilteredByTab, userRole, updatingChat);
            }
          }
        } else if (message.action === ActionsSocketNames.CHAT_UNREMOVED) {
          const updatingChat = state.chats.find(
            (c) => c.chat_id === message.chat_id
          );
          if (updatingChat) {
            const isHostAction =
              updatingChat.host_id === message.body.author_id;
            const isStudentAction =
              updatingChat.student_id === message.body.author_id;

            if (
              (state.userRole === "student" && isStudentAction) ||
              (state.userRole === "host" && isHostAction)
            ) {
              dispatch({
                type: ChatActions.UNREMOVE_CHAT,
                payload: {
                  chatId: message.body.chat_id
                }
              });
              const { chatsFilteredByTab, userRole } = state;
              goToNextChatInLine(chatsFilteredByTab, userRole, updatingChat);
            }
          }
        } else if (message.action === ActionsSocketNames.CHAT_ACCEPTED) {
          const { chat_id } = message;
          dispatch({
            type: ChatActions.ACCEPT_CHAT,
            payload: {
              chatId: chat_id
            }
          });
          cacheHelpers.changeMatchStatus(messageChat, MatchStatusEnum.CHATTED);

          const updatingChat = state.chats.find((c) => c.chat_id === chat_id);
          const { chatsFilteredByTab, userRole } = state;
          if (
            updatingChat &&
            chat_id === state.activeChatId &&
            userRole === "host"
          ) {
            goToNextChatInLine(chatsFilteredByTab, userRole, updatingChat);
          }
        }
      };

      socket.on(SocketEvents.MESSAGE, messageHandler);
      return () => {
        socket.off(SocketEvents.MESSAGE, messageHandler);
      };
    }
  }, [socket, currentUserId]);

  return (
    <ContextChat.Provider
      value={{
        state,
        setActiveChatId: useCallback((chatId: string | null) => {
          dispatch({
            type: ChatActions.SET_ACTIVE_CHAT_ID,
            payload: chatId
          });
        }, []),
        loadMoreMessages,
        readMessage: useCallback(
          (message) => {
            if (!socket) {
              return;
            }
            socket.send(
              createReadMessageObject(
                message.chat_id,
                message.author_id,
                message.created_at
              )
            );
            socket.send({
              action: MessageActions.MESSAGE_READ,
              ...message
            });
          },
          [socket]
        ),
        editMessage: useCallback(
          (message) => {
            if (!socket) {
              return;
            }
            dispatch({
              type: ChatActions.CHANGE_MESSAGE_OPERATION,
              payload: {
                created_at: message.created_at,
                chat_id: message.chat_id,
                operation: MessageOperation.UPDATING
              }
            });
            socket.send({
              action: MessageActions.MESSAGE_UPDATE,
              ...message
            });
          },
          [socket]
        ),
        deleteMessage: useCallback(
          (message) => {
            if (!socket) {
              return;
            }
            dispatch({
              type: ChatActions.CHANGE_MESSAGE_OPERATION,
              payload: {
                created_at: message.created_at,
                chat_id: message.chat_id,
                operation: MessageOperation.DELETING
              }
            });
            socket.send({
              action: MessageActions.MESSAGE_DELETE,
              ...message
            });
          },
          [socket]
        ),
        sendMessage: useCallback(
          (message) => {
            if (!socket) {
              return;
            }
            const traceId = Socket.createTraceId();
            socket.send({
              trace_id: traceId,
              action: MessageActions.MESSAGE_NEW,
              is_read: true,
              ...message
            } as ISocketMessage);

            const createdAt = message.msg_ts;

            dispatch({
              type: ChatActions.ADD_TEMP_MESSAGE,
              payload: {
                author_id: message.author_id,
                chat_id: message.chat_id,
                body: message.body,
                is_read: true,
                trace_id: traceId,
                operation: MessageOperation.CREATING,
                created_at: createdAt,
                updated_at: createdAt
              } as TMessage
            });
          },
          [socket]
        ),
        setChatsFilteredByTab: useCallback((chats) => {
          dispatch({
            type: ChatActions.SET_CHATS_FILTERED_BY_TAB,
            payload: chats
          });
        }, []),
        changeMobileViewMode: useCallback((mode) => {
          dispatch({
            type: ChatActions.CHANGE_MOBILE_VIEW_MODE,
            payload: mode
          });
        }, [])
      }}
    >
      {children}
    </ContextChat.Provider>
  );
};
